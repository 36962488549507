.no-permission {
  padding: 10% 20% 0 20%;
  position: relative;
  height: 100%;
  .plane-img {
    position: absolute;
    top: 10%;
    left: 20%;
    width: 136px;
    height: 123px;
  }
  .content {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    .f0f-bg-img {
      width: 506px;
      height: 403px;
    }
    .bottom {
      margin-top: 0px;
      .notice {
        .text {
          font-size: 18px;
          font-family: SimSun;
          font-weight: 400;
          color: #399ffe;
          line-height: 41px;
        }
      }
      .btns {
        margin-top: 30px;
        text-align: center;
        button:nth-child(2) {
          margin-left: 20px;
        }
      }
    }
  }
}
