.container {
  padding: 0.75rem;
  margin: 0;
}
.header {
  height: 48px !important;
  background-color: #1895a8 !important;
  position: fixed;
  z-index: 1;
  width: "100%";
  top: 0;
  left: 0;
  right: 0;
  .content {
    height: 100%;
    display: flex;
    align-items: center;
    color: white;
    font-weight: 500;
  }
}
.logo {
  margin: 16px 0;
  color: black;
  img {
    width: 58px;
    height: 58px;
  }
  a {
    color: black;
  }
}

.ant-menu-item-active,
.ant-menu-item-active a,
.ant-menu-item-active a:hover {
  color: #1895a8 !important;
}

.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #1895a8 !important;
}

.ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-vertical .ant-menu-item:after {
  border-right: 3px solid #1895a8 !important;
}
