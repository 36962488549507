.not-found {
  padding: 10% 20% 0 20%;
  position: relative;
  height: 100%;
  .plane-img {
    position: absolute;
    top: 10%;
    left: 20%;
    width: 136px;
    height: 123px;
  }
  .content {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    .f-imgs {
      display: flex;
      align-items: center;
      justify-content: center;
      .f1-img {
        width: 96px;
        height: 129px;
      }
      .f0f-bg-img {
        width: 412px;
        height: 246.5px;
        z-index: 1000;
        margin-left: -30px;
      }
      .f2-img {
        margin-left: -100px;
        width: 96px;
        height: 129px;
      }
    }
    .bottom {
      margin-top: 50px;
      .notice {
        .text {
          font-size: 18px;
          font-family: SimSun;
          font-weight: 400;
          color: #399ffe;
          line-height: 41px;
        }
      }
      .btns {
        margin-top: 30px;
        text-align: center;
        button:nth-child(2) {
          margin-left: 20px;
        }
      }
    }
  }
}
