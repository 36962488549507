@tailwind base;
@tailwind components;
@tailwind utilities;
svg {
  vertical-align: baseline;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* font-family: "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei",
    "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif !important; */
  font-family: Source Han Sans CN !important;
  /* font-family: system-ui !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.items-center {
  display: flex;
  align-items: center;
}
.red-price {
  color: red;
}
.red-price::before {
  content: "￥";
  font-size: 12px;
  color: red;
}
.green-price {
  color: green;
}
.green-price::before {
  content: "￥";
  font-size: 12px;
  color: green;
}
.text-price::before {
  content: "￥";
  font-size: 12px;
}

/* .ant-modal-body {
  padding-bottom: 12px !important;
} */

.search-btn-bg {
  background-color: #2db7f5 !important;
  border-color: #2db7f5 !important;
}
