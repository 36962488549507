.container {
  background-color: #f9f9f9;
  height: 100%;
  .home {
    padding: 0;
    background: url("../../assets/imgs/chhy_bg.png") no-repeat;
    background-size: contain;
    background-position: center center;
    height: 100%;
  }
}
