.login-container {
  position: relative;
  background-color: white;
  display: flex;
  .left {
    background-color: #046791;
    width: 72%;
    .content {
      position: relative;
      background: url("../../assets/imgs/login_left_bg.png") no-repeat;
      background-size: cover;
      background-position: center center;
      width: 100%;
      img {
        display: inline-block;
        position: absolute;
        top: 13.8%;
        left: 0.5%;
        width: 79%;
        height: 69.4;
      }
    }
  }
  .right {
    background-color: #0394a9;
    width: 28%;
    .content {
      background: url("../../assets/imgs/login_right_bg.png") no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }
  .form-wrapper {
    position: absolute;
    top: 50%;
    right: 28%;
    transform: translate(50%, -50%);
    width: 28%;
    height: 32.5rem;
    min-width: 25rem;
    max-height: 37.5rem;
    .content {
      position: relative;
      height: 100%;
      width: 100%;
      .form-box {
        position: relative;
        border-radius: 1.375rem;
        padding: 3.75rem;
        color: #666;
        width: 100%;
        height: 100%;
        background: white;
        opacity: 1;
        z-index: 1000;
        .title {
          font-size: 1.75rem;
          font-weight: bold;
          color: #333333;
          text-align: center;
          font-style: italic;
          margin-bottom: 3rem;
        }
        button {
          background-color: #0394a9;
        }
        .footer {
          position: absolute;
          bottom: 10%;
          left: 10%;
          right: 10%;
          text-align: center;
          .qrcode {
            display: flex;
            position: relative;
            flex-direction: row;
            text-align: center;
            justify-content: space-between;
            img {
              width: 5.5rem;
              height: 5.5rem;
            }
          }
        }
      }
      .bg-2 {
        position: absolute;
        border-radius: 1.375rem;
        top: 0.3125rem;
        left: 0.3125rem;
        width: 100%;
        height: 100%;
        background: white;
        opacity: 0.27;
      }
      .bg-3 {
        position: absolute;
        border-radius: 1.375rem;
        top: 0.625rem;
        left: 0.625rem;
        width: 100%;
        height: 100%;
        background: white;
        opacity: 0.27;
      }
    }
  }
  @media screen and (max-height: 640px) {
    .form-wrapper {
      width: 22%;
      height: 26rem;
      min-width: 23rem;
      .content {
        .form-box {
          border-radius: 1rem;
          padding: 2rem;
          .title {
            font-size: 1.4rem;
            margin-bottom: 1.8rem;
          }
          button {
            padding: 0;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
          }
          .footer {
            .qrcode {
              img {
                width: 5rem;
                height: 5rem;
              }
            }
          }
        }
      }
    }
  }
  .bottom {
    position: fixed;
    text-align: center;
    bottom: 0.375rem;
    background: transparent;
    color: #dddddd;
    border-top: 0;
    left: 0;
    right: 0;
    font-size: 0.75rem;
    a {
      color: white;
      text-decoration: none;
    }
  }
}
